import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import ImageUpload from './components/ImageUpload';
import PdfViewer from './components/PdfViewer';
import Footer from './components/Footer';

import { convertToPDF, signPdf, signPdfWithPgp } from './utils/pdfUtils';
import { selectCertificate, handlePgpKeyChange } from './utils/certUtils';

function App() {
  const [imageUrl, setImageUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);
  const [certificateFile, setCertificateFile] = useState(null);
  const [pgpKey, setPgpKey] = useState(null);
  const [passphrase, setPassphrase] = useState('');
  const [pdfBytes, setPdfBytes] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const url = URL.createObjectURL(file);
    setImageUrl(url);
  };

  return (
    <div>
      <Header />
      <div className='uk-container uk-container-muted'>
        <div className='uk-grid uk-child-width-expand@s' uk-grid='true'>
          <div>
            <ImageUpload imageUrl={imageUrl} handleFileChange={handleFileChange} />
          </div>
          <div>
            <div className='uk-card uk-card-default uk-card-body uk-text-center'>
              <h3 className='uk-heading-divider uk-text-center'>Convert to PDF</h3>
              {!imageUrl ? (
                <div>Take or upload a photo to convert it to PDF.</div>
              ) : pdfUrl ? (
                <div>
                  <a href={pdfUrl} download='converted_image.pdf' className='uk-button uk-button-default'>
                    Download PDF
                  </a>
                </div>
              ) : (
                <button className='uk-button uk-button-default' onClick={() => convertToPDF(imageUrl, setPdfBytes, setPdfUrl)}>
                  Convert to PDF
                </button>
              )}
            </div>
          </div>
          <div>
            <PdfViewer
              pdfUrl={pdfUrl}
              signedUrl={signedUrl}
              convertToPDF={convertToPDF}
              signPdf={() => signPdf(certificateFile, pdfBytes, setSignedUrl)}
              signPdfWithPgp={() => signPdfWithPgp(pgpKey, pdfBytes, passphrase, setSignedUrl)}
              certificateFile={certificateFile}
              handlePgpKeyChange={(e) => handlePgpKeyChange(e, setPgpKey)}
              selectCertificate={(e) => selectCertificate(e, setCertificateFile)}
              passphrase={passphrase}
              setPassphrase={setPassphrase}
              pgpKey={pgpKey}
            />
          </div>
        </div>
      </div>
      <Footer handleFileChange={handleFileChange} />
    </div>
  );
}

export default App;
