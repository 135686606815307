import React from 'react';

function Footer({ handleFileChange }) {
  return (
    <div className='uk-container uk-container-default'>
      <footer className='uk-position-bottom'>
        <div className='uk-container'>
          <div className='uk-card uk-card-default uk-card-body uk-text-center'>
            <form className='uk-form'>
              <div uk-form-custom='target: true'>
                <input type='file' aria-label='Custom controls' accept='image/*;capture=camera' onChange={handleFileChange} />
                <input className='uk-input uk-form-width-medium' type='text' placeholder='Select file' aria-label='Custom controls' disabled />
              </div>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
