export const selectCertificate = (event, setCertificateFile) => {
  const certificate = event.target.files[0];
  const passphrase = prompt('Enter passphrase for the certificate:');
  setCertificateFile({ file: certificate, passphrase: passphrase });
};
  
export const handlePgpKeyChange = (event, setPgpKey) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = () => {
    const keyData = reader.result;
    console.log("Loaded PGP Key Data:", keyData);
    setPgpKey(keyData);
  };
  reader.readAsText(file);
};
  