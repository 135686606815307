import React from 'react';

function ImageUpload({ imageUrl, handleFileChange }) {
  return (
    <div className='uk-card uk-card-default uk-card-body uk-text-center'>
      <h3 className='uk-heading-divider uk-text-center'>Uploaded image</h3>
      {imageUrl ? (
        <img src={imageUrl} alt='Uploaded' className='uk-thumbnail' />
      ) : (
        <div>No image uploaded</div>
      )}
    </div>
  );
}

export default ImageUpload;
