import React from 'react';

function Header() {
  return (
    <div className='uk-container' style={{ marginBlock: '55px', padding: '20px' }}>
      <header className='uk-position-top'>
        <div className='uk-container'>
          <h1 className='uk-heading-medium uk-text-center'>VODE proof of concept</h1>
        </div>
      </header>
    </div>
  );
}

export default Header;
