// src/utils/pdfUtils.js
import { PDFDocument } from 'pdf-lib';
import { pdflibAddPlaceholder } from '@signpdf/placeholder-pdf-lib';
import signpdf from '@signpdf/signpdf';
import { P12Signer } from '@signpdf/signer-p12';
import * as openpgp from 'openpgp';

// Convert image to PDF
export const convertToPDF = async (imageUrl, setPdfBytes, setPdfUrl) => {
  try {
    const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const jpgImage = await pdfDoc.embedJpg(imageBytes);
    const page = pdfDoc.addPage();
    const imageWidth = jpgImage.width;
    const imageHeight = jpgImage.height;
    page.setSize(imageWidth, imageHeight);
    page.drawImage(jpgImage, {
      x: 0,
      y: 0,
      width: imageWidth,
      height: imageHeight,
    });
    const pdfFile = await pdfDoc.save();
    setPdfBytes(pdfFile);
    console.log('Generated PDF file:', pdfFile); // Log PDF file to inspect
    const pdfUrl = URL.createObjectURL(new Blob([pdfFile], { type: 'application/pdf' }));
    setPdfUrl(pdfUrl);
  } catch (error) {
    console.error('Error converting image to PDF:', error);
  }
};

// Sign PDF with P12 certificate
export const signPdf = async (certificateFile, pdfBytes, setSignedUrl) => {
  if (!certificateFile) {
    console.error('Certificate file not selected');
    return;
  }

  try {
    const reader = new FileReader();
    reader.onload = async () => {
      const certificateData = new Uint8Array(reader.result);
      const signer = new P12Signer(
        certificateData,
        { passphrase: certificateFile.passphrase }
      );
      const pdfFile = await PDFDocument.load(pdfBytes);
      pdflibAddPlaceholder({
        pdfDoc: pdfFile,
        reason: 'I signed it because I can.',
        contactInfo: 'greg@hluska.ca',
        name: 'Greg Hluska',
        location: 'Some geolocation'
      });
      const signedPDFBytes = await pdfFile.save();
      const signedPdf = await signpdf.sign(signedPDFBytes, signer);
      const signedUrl = URL.createObjectURL(new Blob([signedPdf], { type: 'application/pdf' }));
      setSignedUrl(signedUrl);
    };
    reader.readAsArrayBuffer(certificateFile.file);
  } catch (error) {
    console.error('Error signing PDF:', error);
  }
};

// Sign PDF with PGP key
export const signPdfWithPgp = async (pgpKey, pdfBytes, passphrase, setSignedUrl) => {
  if (!pgpKey) {
    console.error('PGP key not selected');
    return;
  }

  try {
    const privateKey = await openpgp.decryptKey({
      privateKey: await openpgp.readPrivateKey({ armoredKey: pgpKey }),
      passphrase
    });

    if (!privateKey || privateKey.length === 0) {
      console.error('Error: No keys found in the PGP key data');
      return;
    }  
    const pdfMessage = await openpgp.createMessage({ binary: new Uint8Array(pdfBytes) });
    const signed = await openpgp.sign({
      message: pdfMessage, // Input as Message object
      signingKeys: privateKey, // Use the private key to sign the message
      detached: true // Produce a detached signature
    });

    const signedBlob = new Blob([pdfBytes, '\n\n', signed], { type: 'application/pdf' });
    const signedUrl = URL.createObjectURL(signedBlob);
    setSignedUrl(signedUrl);
  } catch (error) {
    console.error('Error signing PDF with PGP:', error);
  }
};
