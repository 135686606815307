import React from 'react';

function PdfViewer({ pdfUrl, signedUrl, convertToPDF, signPdf, signPdfWithPgp, certificateFile, handlePgpKeyChange, selectCertificate, passphrase, setPassphrase, pgpKey }) {
  return (
    <div className='uk-card uk-card-default uk-card-body'>
      {!pdfUrl ? (
        <div>Please generate a PDF before signing.</div>
      ) : signedUrl ? (
        <div>
          <a href={signedUrl} download='signed_image.pdf' className='uk-button uk-button-default'>
            Download Signed PDF
          </a>
        </div>
      ) : (
        <div>
          {!certificateFile && !pgpKey ? (
            <div className='uk-text-center'>
              <div>
                <h3 className='uk-heading-divider uk-text-center'>Select Signing Method</h3>
                <div>
                  <form className='uk-form'>
                    <div uk-form-custom='target: true'>
                      <input type='file' aria-label='Custom controls' accept='.p12' onChange={selectCertificate} />
                      <input className='uk-input uk-form-width-medium' type='text' placeholder='Select P12 certificate' aria-label='Custom controls' disabled />
                    </div>
                  </form>
                </div>
                <hr/>
                <div>
                  <form className='uk-form'>
                    <div uk-form-custom='target: true'>
                      <input type='file' aria-label='Custom controls' accept='.asc,.gpg,.key' onChange={handlePgpKeyChange} />
                      <input className='uk-input uk-form-width-medium' type='text' placeholder='Select PGP key' aria-label='Custom controls' disabled />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h3 className='uk-heading-divider uk-text-center'>Enter PGP Passphrase</h3>
              <input
                className='uk-input uk-form-width-medium'
                type='password'
                placeholder='Enter PGP passphrase'
                value={passphrase}
                onChange={(e) => setPassphrase(e.target.value)}
              />
              <button className='uk-button uk-button-default' onClick={certificateFile ? signPdf : signPdfWithPgp}>
                Sign PDF
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PdfViewer;
